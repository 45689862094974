/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const cross = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.00004 1.33301C4.31337 1.33301 1.33337 4.31301 1.33337 7.99967C1.33337 11.6863 4.31337 14.6663 8.00004 14.6663C11.6867 14.6663 14.6667 11.6863 14.6667 7.99967C14.6667 4.31301 11.6867 1.33301 8.00004 1.33301ZM11.3334 10.3927L10.3934 11.3327L8.00008 8.93935L5.60675 11.3327L4.66675 10.3927L7.06008 7.99935L4.66675 5.60602L5.60675 4.66602L8.00008 7.05935L10.3934 4.66602L11.3334 5.60602L8.94008 7.99935L11.3334 10.3927Z" fill="#666666" />
    </svg>
);

const CrossIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return cross({ ...innerProps, ...props });
    }}
    />
);

export default CrossIcon;
