/* eslint-disable max-len */
import {
    Button, Form, message, Carousel,
} from 'antd';
import React, { useState } from 'react';
import { pageHeading } from './createProfile.constants';
import withStyles from 'react-jss';
import StepOnePage from './StepOne';
import StepTwoPage from './StepTwo';
import StepThreePage from './StepThree';
import { useGenericState } from 'hooks/use-generic-state';
import { createSeller } from 'api/createProfile';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCreateSuccess } from 'actions/masterdata';
import SuccessPage from 'common/SuccessPage';
import { isMobile } from 'react-device-detect';

const config = require('../config').default;

const { BANNER_IMAGE_URL, CAROUSEL_CONFIG } = config;

const styles = () => ({
    header: {
        height: '60px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'white',
        fontWeight: 500,
        backgroundColor: '#0E2C53',
        padding: '0px 20px',
    },
    actionButton: {
        borderRadius: '15px',
        width: '100%',
        marginBottom: '40px',
        backgroundColor: '#0E2C53 !important',
    },
    prevButton: {
        border: '1px solid #065492',
        borderRadius: '15px',
        width: '100%',
        marginBottom: '40px',
        color: '#065492',
    },
    form: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        backgroundColor: 'white',
        borderTopLeftRadius: '15px',
        borderTopRightRadius: '15px',
        '& .ant-input, .ant-input-affix-wrapper': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-input-number-input': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-checkbox-group-item:last-child': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
        },
        '& .ant-form-item-control-input': {
            minHeight: 24,
        },
        '& .ant-form-item': {
            marginBottom: 0,
        },
        '& .ant-radio-wrapper': {
            display: 'flex',
            color: '#111111',
            fontFamily: 'Open Sans',
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0,
            margin: '4px 24px 4px 0px',
        },
        '& .ant-picker': {
            boxSizing: 'border-box',
            height: 32,
            border: '1px solid #999999',
            borderRadius: 4,
            marginRight: 2,
        },
        '& .ant-form-item-control-input-content': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .ant-form-item-explain-error': {
            fontSize: '10px !important',
        },
    },
    main: {
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#065492',
    },
    rightContainer: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '5%',
    },
    imageStyle: {
        width: '100%',
        borderRadius: '15px',
        boxShadow: '2px 3px #ddd',
    },
    carousel: {
        width: '100%',
        borderRadius: '15px',
        boxShadow: '2px 3px #ddd',
        marginBottom: '40px',
        '& .ant-carousel': {
            width: '100%',
        },
    },
    welcomeHeader: {
        font: 'Open Sans',
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '20px',
        color: '#0E2C53',
    },
    welcomeText: {
        font: 'Open Sans',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#494646',
        marginTop: '15px',
    },
});

const CreateProfile = (props: any) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [form] = Form.useForm();
    const [formData, setFormData] = useGenericState<any>({});
    const [creating, setCreating] = React.useState<boolean>(false);
    const {
        classes, phone, email, seller_id, setCreateSuccess, is_success, seller_code,
    } = props;

    const goToNextPage = async () => {
        updateFormData();
        await form.validateFields();
        setCurrentPage((prev) => prev + 1);
    };

    const goToPreviousPage = async () => {
        updateFormData();
        await form.validateFields();
        setCurrentPage((prev) => prev - 1);
    };

    const renderHeader = () => {
        return (
            <div style={{
                height: '30px', fontWeight: 700, display: 'flex', alignItems: 'end', marginBottom: '20px', marginTop: '20px', fontSize: '14px',
            }}
            >
                {pageHeading[currentPage]}
            </div>
        );
    };

    const renderForm = () => {
        const commonProps = {
            formData,
            form,
            setFormData,
        };
        switch (currentPage) {
            case 1:
                return <StepOnePage {...commonProps} />;
            case 2:
                return <StepTwoPage {...commonProps} />;
            case 3:
                return <StepThreePage {...commonProps} />;
        }
    };

    const updateFormData = () => {
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
        };
        setFormData(updatedData);
    };
    async function getAPIBody() {
        updateFormData();
        const formValues = form.getFieldsValue();
        const updatedData = {
            ...formData,
            ...formValues,
        };
        const updatedKycDocuments = updatedData.kyc_documents
            ? updatedData.kyc_documents.map((doc: any) => ({
                ...doc,
                dob: doc.dob ? moment(doc.dob).format('DD/MM/YYYY') : null,
                front_image: undefined,
                back_image: undefined,
            }))
            : [];

        const apiBody = {
            seller_id,
            phone: updatedData.phone || phone,
            alternate_phone: updatedData.alternate_phone,
            email: updatedData.sender_email || email,
            seller_name: updatedData.seller_name,
            company_name: updatedData.company_name,
            sender_type: updatedData.sender_type,
            authorization_letters: updatedData.authorization_letters || [],
            bank_details: updatedData.bank_details || {},
            kyc_documents: updatedKycDocuments,
            location_details: updatedData.location_details || {},
            is_csbv_applicable: updatedData.is_csbv_applicable === 'yes',
        };
        return {
            apiBody,
        };
    }
    async function checkForErrors() {
        updateFormData();
        await form.validateFields();
        const {
            apiBody,
        } = await getAPIBody();
        setCreating(true);
        const response = await createSeller(apiBody);
        if (response.isSuccess) {
            setCreateSuccess({
                seller_code: response.data?.requiredSeller?.code,
                is_success: true,
            });
        } else {
            message.error(response.errorMessage);
        }
        setCreating(false);
    }

    const renderAction = () => {
        switch (currentPage) {
            case 1:
                return <Button type="primary" className={classes.actionButton} onClick={goToNextPage}>Next</Button>;
            case 2:
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button className={classes.prevButton} onClick={goToPreviousPage} style={{ width: '48%' }}>Previous</Button>
                        <Button type="primary" className={classes.actionButton} style={{ width: '48%' }} onClick={goToNextPage}>Next</Button>
                    </div>
                );
            case 3:
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button disabled={creating} className={classes.prevButton} style={{ width: '48%' }} onClick={goToPreviousPage}>Previous</Button>
                        <Button type="primary" className={classes.actionButton} style={{ width: '48%' }} onClick={checkForErrors}>Create Profile</Button>
                    </div>
                );
        }
    };

    if (is_success) {
        return <SuccessPage message={`Your consignor code ${seller_code} has been generated. Your profile is submitted for verification. Stay tuned for updates.`} />;
    }
    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <div style={{
                    font: 'Open Sans', fontWeight: 600, fontSize: '16px', lineHeight: '28px',
                }}
                >
                    Profile Registration
                </div>
                <div>
                    <img
                        src="https://s3-alpha-sig.figma.com/img/afd9/73db/18da7becf727fa09b3a86d3ed3d62dab?Expires=1739145600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=RSDFqPVmFlAakifcgSv0IaSb~v2HGiajnLlWSWaRYiSJ6A6xuKHf3cBveF~BJIk7HQ-D8GnNy77BLqz93MEuher3SIkNtp2Z4auajw3vkitp7IAYreAdFS4nApGcN4IWKXTLiPoCUUo7nIW22wj6n~slfnzxHvdJcMSCPkC6ZfTU~lMURV0vCtEblavCKgERl~FW9Wdz6RHxAkdWqVpdPj9tSLfVCeNX-pkYJL21hlkKNr58VRaoOYxXIDE~fUrx5wCDdLMf1yvGMWZs-iAL0k~yztfSIQ5WI~kan~mg0ZI5F5ZBEIWdhazbwZ4YqMQdNyT8IqZaBbbPdnh9uUtB~Q__"
                        alt="dtdc-logo"
                        style={{ width: '110px', height: '30px', marginRight: '40px' }}
                    />
                </div>
            </div>
            <div style={{
                height: 'calc(100% - 60px)', display: 'flex', backgroundColor: 'white', width: '100%',
            }}
            >
                <div className={classes.form}>
                    <div style={{ width: isMobile ? '100%' : '50%', margin: isMobile ? '0' : 'auto' }}>
                        {currentPage === 1 && (
                            <div style={{ paddingTop: '20px' }}>
                                <div className={classes.welcomeHeader}>
                                    Welcome to DTDC!
                                </div>
                                <div className={classes.welcomeText}>
                                    To ensure smooth processing of your international shipments, please register and complete your profile with accurate KYC details.
                                </div>
                            </div>
                        )}
                        {renderHeader()}
                        <div>
                            <Form form={form}>
                                {renderForm()}
                            </Form>
                        </div>
                        {renderAction()}
                    </div>
                </div>
                {!isMobile ? (
                    <div className={classes.rightContainer}>
                        <div className={classes.carousel}>
                            <Carousel autoplay>
                                {
                                    CAROUSEL_CONFIG.map((item: any, index: number) => {
                                        return <div><img key={index} className={classes.imageStyle} src={item.image_link} /></div>;
                                    })
                                }
                            </Carousel>
                        </div>
                        <div><img className={classes.imageStyle} src={BANNER_IMAGE_URL} /></div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    const { masterdataReducer } = state;
    return {
        phone: masterdataReducer.phone,
        email: masterdataReducer.email,
        seller_id: masterdataReducer.seller_id,
        is_success: masterdataReducer.is_success,
        seller_code: masterdataReducer.seller_code,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            setCreateSuccess,
        },
        dispatch,
    );
};

const CreateProfilePage = withStyles(styles, { injectTheme: true })(
    (CreateProfile) as React.ComponentType<any>,
);
export default connect(mapStateToProps, mapDispatchToProps)(CreateProfilePage);
