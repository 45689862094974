import CircleCheckIcon from "assets/circle-check-icon";
export const createSellerProfileFormFields = {
    senderType: {
        label: 'Sender Type',
        placeholder: 'Select Type',
        type: 'Select',
        options: [] as any,
        key: 'sender_type',
        valuePropName: 'value',
        isRequired: true,
    },
    csbvApplicable: {
        label: 'Is CSB V Applicable',
        placeholder: 'Select CSB Applicable',
        type: 'Radio',
        key: 'is_csbv_applicable',
        defaultValue: 'no',
        valuePropName: 'value',
        options: [
            {
                value: 'yes',
                label: 'Yes',
            },
            {
                value: 'no',
                label: 'No',
            },
        ],
    },
    addressType: {
        label: 'Address Type',
        type: 'Radio',
        disabled: true,
        key: 'address_type',
        keyPath: ['location_details', 'address_type'],
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        isRequired: true,
        valuePropName: 'value',
        options: [
            {
                value: 'individual',
                label: 'Individual',
            },
            {
                value: 'business',
                label: 'Business',
            },
        ],
    },
    senderName: {
        label: 'Name',
        placeholder: 'Enter Name',
        type: 'Input',
        rules: [
            {
                max: 75,
                message: 'Maximum 75 characters',
            },
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
            {
                required: true,
                message: 'Required',
            },
        ],
        key: 'seller_name',
        valuePropName: 'value',
        isRequired: true,
    },
    senderCompanyName: {
        label: 'Company Name',
        placeholder: 'Enter Company Name',
        type: 'Input',
        key: 'company_name',
        valuePropName: 'value',
        rules: [
            {
                max: 50,
                message: 'Maximum 50 characters',
            },
        ],
    },
    senderAddressLine1: {
        label: 'Address Line 1',
        placeholder: 'Enter Address Line 1',
        type: 'Input',
        rules: [
            {
                min: 3,
                message: 'Minimum 3 characters',
            },
            {
                max: 100,
                message: 'Maximum 100 characters',
            },
            {
                required: true,
                message: 'Required',
            },
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
        ],
        key: 'address_line_1',
        keyPath: ['location_details', 'address_line_1'],
        valuePropName: 'value',
        isRequired: true,
    },
    senderAddressLine2: {
        label: 'Address Line 2',
        placeholder: 'Enter Address Line 2',
        type: 'Input',
        key: 'address_line_2',
        keyPath: ['location_details', 'address_line_2'],
        valuePropName: 'value',
        rules: [
            {
                min: 3,
                message: 'Minimum 3 characters',
            },
            {
                max: 100,
                message: 'Maximum 100 characters',
            },
            {
                pattern: /^[\x20-\x7E]*$/,
                message: 'Only ASCII characters are allowed',
            },
        ],
    },
    senderPincode: {
        label: 'Pincode',
        placeholder: 'Enter Pincode',
        type: 'Input',
        key: 'pincode',
        keyPath: ['location_details', 'pincode'],
        valuePropName: 'value',
        rules: [
            {
                required: true,
                message: 'Required',
            },
            {
                pattern: /^\d{6}$/,
                message: 'Invalid Pincode',
            },
        ],
        isRequired: true,
    },
    senderCity: {
        label: 'City',
        placeholder: 'Enter City',
        type: 'Input',
        key: 'city',
        keyPath: ['location_details', 'city'],
        valuePropName: 'value',
        isRequired: true,
    },
    senderState: {
        label: 'State',
        placeholder: 'Enter State',
        type: 'Input',
        isRequired: true,
        key: 'state',
        keyPath: ['location_details', 'state'],
        valuePropName: 'value',
    },
    senderCountry: {
        label: 'Country',
        placeholder: 'Enter Country',
        type: 'Input',
        key: 'country',
        defaultValue: 'India',
        keyPath: ['location_details', 'country'],
        valuePropName: 'value',
        isRequired: true,
    },
    senderMobileNumber: {
        label: 'Mobile Number',
        placeholder: 'Enter Mobile Number',
        type: 'Input',
        key: 'phone',
        valuePropName: 'value',
        isRequired: true,
        disabled: false,
    },
    senderAlternateMobileNumber: {
        label: 'Alternate Mobile Number',
        placeholder: 'Enter Alternate Mobile Number',
        type: 'Input',
        key: 'alternate_phone',
        valuePropName: 'value',
        rules: [
            {
                pattern: /^(\+91\s?|\(?0\)?)?\d{10}$/,
                message: 'Invalid Phone',
            },
        ],
    },
    senderEmail: {
        label: 'Email',
        placeholder: 'Enter Email',
        type: 'Input',
        key: 'sender_email',
        rules: [
            {
                pattern: /\S+@\S+\.\S+/,
                message: 'Invalid email',
            },
            {
                required: true,
                message: 'Required',
            },
        ],
        disabled: false,
        isRequired: true,
        valuePropName: 'value',
    },
    addressProofType: {
        label: 'Address Proof Type',
        placeholder: 'Select Address Proof Type',
        type: 'Select',
        isRequired: true,
        key: 'address_proof_type',
        keyPath: ['location_details', 'address_proof_type'],
        valuePropName: 'value',

    },
    addressProofFront: {
        label: 'Upload Proof',
        placeholder: 'Upload Address Proof Front',
        type: 'Upload',
        isRequired: true,
        key: 'address_proof_front_image',
        maxFileSize: 3,
        valuePropName: 'value',
        showPreview: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
    },
    addressProofBack: {
        label: ' ',
        placeholder: 'Upload Address Proof Back',
        type: 'Upload',
        isRequired: true,
        maxFileSize: 3,
        key: 'address_proof_back_image',
        valuePropName: 'value',
        showPreview: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
    },
    bankAccountNumber: {
        label: 'Account Number',
        placeholder: 'Enter Bank Account Number',
        type: 'Input',
        key: 'account_number',
        keyPath: ['bank_details', 'account_number'],
        valuePropName: 'value',
        rules: [
            {
                max: 50,
                message: 'Maximum 50 characters',
            },
        ],
    },
    bankAdCode: {
        label: 'AD Code',
        placeholder: 'Enter Bank AD Code',
        type: 'Input',
        key: 'ad_code',
        keyPath: ['bank_details', 'ad_code'],
        valuePropName: 'value',
        rules: [
            {
                pattern: /^\d{14}$/,
                message: 'Invalid AD code!',
            },
        ],
    },
    bankIfscCode: {
        label: 'IFSC Code',
        placeholder: 'Enter Bank IFSC Code',
        type: 'Input',
        key: 'ifsc_code',
        keyPath: ['bank_details', 'ifsc_code'],
        valuePropName: 'value',
        rules: [
            {
                pattern: /^[A-Za-z]{4}\d{7}$/,
                message: 'Invalid IFSC code!',
            },
        ],
    },
    bankName: {
        label: 'Bank Name',
        placeholder: 'Enter Bank Name',
        type: 'Input',
        key: 'bank_name',
        keyPath: ['bank_details', 'bank_name'],
        valuePropName: 'value',
        rules: [
            {
                max: 500,
                message: 'Maximum 500 characters',
            },
        ],
    },
    bankType: {
        label: 'Bank Type',
        placeholder: 'Enter Bank Type',
        type: 'Radio',
        key: 'bank_type',
        keyPath: ['bank_details', 'bank_type'],
        valuePropName: 'value',
        options: [
            {
                value: 'Government',
                label: 'Government',
            },
            {
                value: 'Private',
                label: 'Private',
            },
        ],
    },
    AdCodeCertification: {
        label: 'AD Code Certification',
        type: 'Upload',
        key: 'ad_code_certificate',
        imageKey: 'Upload',
        imageExt: ['bank_details', 'image_details', 'ext'],
        valuePropName: 'value',
        smallMessage1: 'Supported file types: .JPG, .JPEG, .PDF',
        smallMessage2: 'Max file size: 3MB',
        maxFileSize: 3,
        showPreview: true,
    },
    documentType: {
        label: 'Document Type',
        placeholder: 'Select Document Type',
        type: 'Select',
        key: 'document_type',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: '50%'
        },
    },
    documentNumber: {
        label: 'Document Number',
        placeholder: 'Enter Document Number',
        type: 'Input',
        key: 'document_number',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: '50%'
        },
    },
    documentFileNumber: {
        label: 'Document File Number',
        placeholder: 'Enter Document File Number',
        type: 'Input',
        key: 'file_number',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: '50%'
        },
    },
    documentDOB: {
        label: 'Document Date of Birth',
        placeholder: 'Enter Document DOB',
        type: 'Date',
        key: 'dob',
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: '50%',
        },
    },
    documentFrontImage: {
        label: 'Upload Proof',
        placeholder: 'Upload Document Front',
        type: 'Upload',
        maxFileSize: 3,
        key: 'front_image',
        showPreview: true,
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: 'auto',
        },
    },
    documentBackImage: {
        label: 'Back Image',
        placeholder: 'Upload Document Back',
        type: 'Upload',
        maxFileSize: 3,
        key: 'back_image',
        showPreview: true,
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        css: {
            width: 'auto',
        },
    },
    embedSignature: {
        label: '',
        placeholder: 'Upload Signature',
        type: 'Upload',
        key: 'signature_image',
        imageExt: ['signature_image_details', 'ext'],
        valuePropName: 'value',
        isRequired: true,
        rules: [
            {
                required: true,
                message: 'Required',
            },
        ],
        maxFileSize: 3,
        smallMessage1: 'Supported file types: .JPG, .JPEG',
        smallMessage2: 'Max file size: 3MB',
        showPreview: true,
    },
    embedSeal: {
        label: '',
        placeholder: 'Upload Seal',
        type: 'Upload',
        key: 'seal_image',
        imageExt: ['seal_image_details', 'ext'],
        maxFileSize: 3,
        valuePropName: 'value',
        smallMessage1: 'Supported file types: .JPG, .JPEG',
        smallMessage2: 'Max file size: 3MB',
        showPreview: true,
    },
};

export const pageHeading : any = {
    1: 'Primary & Address Details',
    2: 'Bank Details',
    3: 'Seal & Signature'
}