/* eslint-disable max-len */
import Icon from '@ant-design/icons';
import * as React from 'react';

const camera = (props: any) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="9.99967" r="2.66667" fill="#232C65" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.97496 3.33366L7.49996 1.66699H12.5L14.025 3.33366H16.6666C17.5833 3.33366 18.3333 4.08366 18.3333 5.00033V15.0003C18.3333 15.917 17.5833 16.667 16.6666 16.667H3.33329C2.41663 16.667 1.66663 15.917 1.66663 15.0003V5.00033C1.66663 4.08366 2.41663 3.33366 3.33329 3.33366H5.97496ZM5.83328 10.0007C5.83328 12.3007 7.69995 14.1673 9.99995 14.1673C12.2999 14.1673 14.1666 12.3007 14.1666 10.0007C14.1666 7.70065 12.2999 5.83398 9.99995 5.83398C7.69995 5.83398 5.83328 7.70065 5.83328 10.0007Z" fill="#232C65" />
    </svg>
);

const CameraIcon = (props: any) => (
    <Icon component={(innerProps: any) => {
        return camera({ ...innerProps, ...props });
    }}
    />
);

export default CameraIcon;
