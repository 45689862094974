import React, { useEffect, useState } from 'react';
import { createSellerProfileFormFields } from './createProfile.constants';
import RenderFormItem from './generic-object';
import withStyles from 'react-jss';
import { Form, message } from 'antd';
import { connect } from 'react-redux';
import { snakeToPretty } from 'library/Helper';
import { callDeleteImage, deleteImage, validateIndividualKyc } from 'api/createProfile';
import moment from 'moment';
import Loader from './Loader';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import GreenTickIcon from 'assets/green-tick-icon';
import { NamePath } from 'antd/lib/form/interface';

const styles = () => ({
    formContainer: {
        backgroundColor: '#F0F5FF',
        borderRadius: '10px',
        margin: '8px 0',
        padding: '10px',
    },
    validate: {
        display: 'flex',
        padding: '5px',
        fontSize: '14px',
    },
    validated: {
        color: '#008D16',
        marginLeft: '10px',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    validating: {
        color: '#006EC3',
        marginLeft: '10px',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    unvalidated: {
        color: '#EA2626',
        marginLeft: '10px',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
    },
    validateIcon: {
        color: '#006EC3',
        marginLeft: '10px',
        cursor: 'pointer',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        size: 12,
    },
    boxFieldsCol: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    label: {
        marginTop: '5px',
        marginLeft: '5px',
        fontSize: '12px',
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
    },
});
const DOBFormat = 'DD/MM/YYYY';

const {
    senderType,
    bankIfscCode,
    bankName,
    bankAdCode,
    bankAccountNumber,
    bankType,
    AdCodeCertification,
    documentType,
    documentBackImage,
    documentFrontImage,
    documentNumber,
    documentDOB,
    documentFileNumber,
    senderName,
    csbvApplicable,
} = createSellerProfileFormFields;

const StepTwo = (props: any) => {
    const {
        form, classes, metadata, formData, setFormData, seller_id,
    } = props;
    const {
        DOCUMENT_LABEL_MAP, DOCUMENT_TYPE_REQUIRED_FIELDS_MAP, DOCUMENT_TYPES_KYC_ENABLED, SELLER_TYPE_DOCUMENTS_UPLOAD_MAP,
        KYC_VALIDATION_REGEX,
    } = metadata;
    const kycDocumentValue = Form.useWatch('kyc_documents', form);
    const [kycInProgress, setKycInProgress] = useState<Record<number, boolean>>({});
    const [loading, setLoading] = useState<boolean>(false);
    const requiredCsbv = form.getFieldValue(csbvApplicable.key);
    const [senderTypeVal, setSenderTypeVal] = useState<any>(formData.sender_type);
    const prevKycDocumentValue = React.useRef(kycDocumentValue);

    const bankAccountNumberVal = Form.useWatch(bankAccountNumber.keyPath as NamePath, form);
    const bankNameVal = Form.useWatch(bankName.keyPath as NamePath, form);
    const bankTypeVal = Form.useWatch(bankType.keyPath as NamePath, form);
    const bankIfscCodeVal = Form.useWatch(bankIfscCode.keyPath as NamePath, form);
    const bankAdCodeVal = Form.useWatch(bankAdCode.keyPath as NamePath, form);

    useEffect(() => {
        if (senderTypeVal !== formData.sender_type) {
            setSenderTypeVal(formData.sender_type);
            form.setFieldsValue({
                kyc_documents: [],
            });
        }
    }, [formData.sender_type]);

    useEffect(() => {
        if (kycDocumentValue) {
            const validatedDocuments = formData?.validatedDocuments || {};
            interface ValidatedDocuments {
                [index: string]: any;
            }

            const newValidatedDocuments: ValidatedDocuments = {};

            kycDocumentValue.forEach((kycDocument: any, index: number) => {
                const document = validatedDocuments[index];
                if (document) {
                    const documentData = kycDocumentValue[index];
                    const isDocumentChanged = Object.keys(documentData).some((key) => documentData[key] !== document[key]);
                    if (isDocumentChanged) {
                        newValidatedDocuments[index?.toString()] = {
                            ...document,
                            isValidated: false,
                            errorMessage: '',
                        };
                    } else {
                        newValidatedDocuments[index] = document;
                    }
                }
            });
            setFormData({
                ...formData,
                validatedDocuments: newValidatedDocuments,
            });
            let body = {};
            let frontImageUrl;
            let backImageUrl;
            const newKycDocumentValue = kycDocumentValue?.map((doc: any, index: number) => {
                const prevDoc = prevKycDocumentValue?.current?.[index];
                if (prevDoc && doc?.document_type !== prevDoc?.document_type) {
                    body = {
                        document_number: doc?.document_number,
                        document_type: prevDoc?.document_type,
                        name: 'kyc',
                        seller_id,
                    };
                    frontImageUrl = doc?.front_image;
                    backImageUrl = doc?.back_image;
                    return { document_type: doc.document_type };
                }
                const values = form.getFieldValue('kyc_documents')?.[index] || {};
                return { ...values, ...doc };
            });
            if (frontImageUrl) {
                deleteKycImage({ ...body, type: 'front_image' });
            }
            if (backImageUrl) {
                deleteKycImage({ ...body, type: 'back_image' });
            }
            form.setFieldsValue({ kyc_documents: newKycDocumentValue });
            prevKycDocumentValue.current = newKycDocumentValue;
        }
    }, [kycDocumentValue]);

    const deleteKycImage = async (body: any) => {
        try {
            const response = await deleteImage(body);
            if (response?.isSuccess) {
                await callDeleteImage(response?.data?.url);
            }
        } catch (error) {
            message.error('Failed to delete image');
        }
    };

    const commonProps = {
        formData,
        form,
        setFormData,
    };

    const renderRowUnvalidated = (index: number) => {
        return (
            formData?.validatedDocuments?.[index]?.errorMessage ? (
                <h5 className={classes.unvalidated}>
                    <ExclamationCircleFilled style={{ color: '#EA2626', fontSize: 16 }} />
                    {' '}
                    Your KYC verification was unsuccessful. Error:
                    {' '}
                    {formData?.validatedDocuments?.[index]?.errorMessage}
                </h5>
            ) : (
                <h5>KYC documents need to be validated to make the profile active.</h5>
            )
        );
    };

    const renderRowValidated = (index: number) => {
        return (
            <h5 className={classes.validated}>
                <GreenTickIcon style={{ backgroundColor: '#E6F8E9', borderRadius: 10 }} />
                {' '}
                Congratulations! Your KYC verification for
                {' '}
                {snakeToPretty(DOCUMENT_LABEL_MAP?.[formData?.validatedDocuments?.[index]?.document_type])}
                {' '}
                was successful.
            </h5>
        );
    };

    const handleValidate = async (index: number) => {
        setKycInProgress({ ...kycInProgress, [index]: true });
        const data = kycDocumentValue?.[index];
        const name = form.getFieldValue(senderName.key);
        const body = {
            ...data,
            dob: data?.dob ? moment(data?.dob).format(DOBFormat) : null,
            name,
            seller_id,
        };
        try {
            setLoading(true);
            const response = await validateIndividualKyc(body);
            if (response.isSuccess) {
                setFormData({
                    ...formData,
                    validatedDocuments: {
                        ...(formData?.validatedDocuments || {}),
                        [index]: {
                            ...kycDocumentValue?.[index],
                            isValidated: true,
                        },
                    },
                });
                message.success('Document validated successfully');
            } else {
                setFormData({
                    ...formData,
                    validatedDocuments: {
                        ...(formData?.validatedDocuments || {}),
                        [index]: {
                            ...kycDocumentValue?.[index],
                            isValidated: false,
                            errorMessage: response.errorMessage,
                        },
                    },
                });
                message.error(response.errorMessage || 'Failed to validate document');
            }
        } catch (error) {
            message.error('Failed to validate document');
        }
        setLoading(false);
        setKycInProgress({ ...kycInProgress, [index]: false });
    };

    const renderValidate = (index: any, disableValidate: boolean, hideValidate: boolean) => {
        if (hideValidate) return null;
        return (
            kycInProgress[index] ? (
                <h5 className={classes.validating}>
                    <LoadingOutlined style={{ color: '#006EC3' }} />
                    {' '}
                    KYC Verification in progress
                </h5>
            ) : (
                <div className={classes.validate}>
                    <div style={{
                        width: formData?.validatedDocuments?.[index]?.isValidated ? '100%' : '85%',
                        wordWrap: 'break-word',
                    }}
                    >
                        {formData?.validatedDocuments?.[index]?.isValidated ? (
                            renderRowValidated(index)
                        ) : (
                            renderRowUnvalidated(index)
                        )}
                    </div>
                    <div
                        onClick={() => !disableValidate && handleValidate(index)}
                        style={{
                            pointerEvents: disableValidate ? 'none' : 'auto',
                            opacity: disableValidate ? 0.4 : 1,
                            width: '15%',
                            textAlign: 'end',
                        }}
                    >
                        {(!formData?.validatedDocuments?.[index]?.isValidated && !hideValidate) ? (
                            <h5 className={classes.validateIcon}>VALIDATE</h5>
                        ) : null}
                    </div>
                </div>
            )
        );
    };


    const renderKycIndividual = (
        index: number, docType: any, docNumber: any, docFrontImage: any, docBackImage: any, docDOB: any, docFileNumber: any,
    ) => {
        let requiredDocumentType = 'check';
        const documentData = kycDocumentValue?.[index];
        requiredDocumentType = documentData?.document_type;
        const DatailsToShowList = DOCUMENT_TYPE_REQUIRED_FIELDS_MAP[requiredDocumentType];
        const showDocNumber = DatailsToShowList?.includes('document_number') ?? false;
        const showFrontImage = DatailsToShowList?.includes('front_image') ?? false;
        const showBackImage = DatailsToShowList?.includes('back_image') ?? false;
        const showDOB = DatailsToShowList?.includes('dob') ?? false;
        const showFileNumber = DatailsToShowList?.includes('file_number') ?? false;
        let disableValidate = !kycDocumentValue?.[index]?.document_type;
        let disableUpload = !kycDocumentValue?.[index]?.document_type;

        const requiredDocNumber = {
            ...docNumber,
        };
        const pattern = KYC_VALIDATION_REGEX.find((elem: any) => elem.document_type
            === requiredDocumentType)?.regex;
        const regex = new RegExp(pattern);
        if (pattern) {
            requiredDocNumber.rules = [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    pattern: regex,
                    message: 'Invalid document number!',
                },
            ];
        }

        const hideValidate = !DOCUMENT_TYPES_KYC_ENABLED?.includes(requiredDocumentType);
        if (showDocNumber) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.document_number || !regex.test(kycDocumentValue?.[index]?.document_number);
            disableUpload = disableUpload || !kycDocumentValue?.[index]?.document_number || !regex.test(kycDocumentValue?.[index]?.document_number);
        }
        if (showFrontImage) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.front_image;
        }
        if (showBackImage) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.back_image;
        }
        if (showDOB) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.dob;
        }
        if (showFileNumber) {
            disableValidate = disableValidate || !kycDocumentValue?.[index]?.file_number;
        }


        return (
            <>
                <div className={classes.formContainer}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <RenderFormItem
                            field={docType}
                            {...commonProps}
                        />
                        {showDocNumber && (
                            <RenderFormItem
                                field={requiredDocNumber}
                                {...commonProps}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {showDOB && (
                            <RenderFormItem
                                field={docDOB}
                                {...commonProps}
                            />
                        )}
                        {showFileNumber && (
                            <RenderFormItem
                                field={docFileNumber}
                                {...commonProps}
                            />
                        )}
                    </div>
                    {showFrontImage && (
                        <div style={{ margin: '0 0 80px 0' }}>
                            <div className={classes.label}>
                                Upload KYC Document *
                            </div>
                            <div style={{
                                display: 'flex', flexDirection: 'row', gap: '0', alignItems: 'center',
                            }}
                            >
                                <RenderFormItem
                                    field={{ ...docFrontImage, disabled: disableUpload }}
                                    {...commonProps}
                                />
                                {showBackImage && (
                                    <RenderFormItem
                                        field={{ ...docBackImage, disabled: disableUpload }}
                                        {...commonProps}
                                        css={{ marginLeft: '0px' }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {showFrontImage && (
                        <div className={classes.formContainer} style={{ borderTop: '1px solid #E8E1E1', paddingTop: '10px' }}>
                            {renderValidate(index, disableValidate, hideValidate)}
                        </div>
                    )}
                </div>
            </>
        );
    };

    const renderBank = () => {
        const isBankDetailsRequired = requiredCsbv === 'yes' || bankAccountNumberVal || bankIfscCodeVal
            || bankAdCodeVal || bankTypeVal || bankNameVal;
        if (!isBankDetailsRequired) {
            form.resetFields(
                [
                    bankName.keyPath as NamePath,
                    bankType.keyPath as NamePath,
                    bankAccountNumber.keyPath as NamePath,
                    bankAdCode.keyPath as NamePath,
                    bankIfscCode.keyPath as NamePath,
                ],
            );
        }
        const requiredBankAccountNumber = {
            ...bankAccountNumber,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    max: 50,
                    message: 'Maximum 50 characters',
                },
            ],
        };
        const requiredBankAdCode = {
            ...bankAdCode,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    pattern: /^\d{14}$/,
                    message: 'Invalid AD code!',
                },
            ],
        };
        const requiredBankIfscCode = {
            ...bankIfscCode,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    pattern: /^[A-Za-z]{4}\d{7}$/,
                    message: 'Invalid IFSC code!',
                },
            ],
        };
        const requiredBankName = {
            ...bankName,
            isRequired: isBankDetailsRequired,
            rules: [
                {
                    required: isBankDetailsRequired,
                    message: 'Required',
                },
                {
                    max: 500,
                    message: 'Maximum 500 characters',
                },
            ],
        };
        const requiredBankType = {
            ...bankType,
            isRequired: isBankDetailsRequired,
        };

        return (
            <div className={classes.formContainer}>
                <RenderFormItem
                    {...commonProps}
                    field={requiredBankAccountNumber}
                />
                <RenderFormItem
                    {...commonProps}
                    field={requiredBankIfscCode}
                />
                <RenderFormItem
                    {...commonProps}
                    field={requiredBankAdCode}
                />
                <RenderFormItem
                    {...commonProps}
                    field={requiredBankName}
                />
                <RenderFormItem
                    {...commonProps}
                    field={requiredBankType}
                />
                <RenderFormItem
                    field={AdCodeCertification}
                    {...commonProps}
                />
            </div>
        );
    };

    const createOptions = (document_type_options: Array<string>) => {
        return document_type_options?.map((option: any) => {
            return {
                value: option,
                label: DOCUMENT_LABEL_MAP[option],
            };
        });
    };

    const renderKyc = () => {
        const requiredSenderType = form.getFieldValue(senderType.key);
        const documentsData = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP[requiredSenderType];
        return (
            <>
                {documentsData?.documents?.map((document: any, index: any) => {
                    if (!document?.document_type_options.includes(form.getFieldValue(['kyc_documents', index, documentType.key]))) {
                        const kyc = [...(form.getFieldValue('kyc_documents') || [])];
                        kyc[index] = {};
                        form.setFieldsValue({
                            kyc_documents: kyc,
                        });
                    }
                    const docType = {
                        ...documentType,
                        options: createOptions(document?.document_type_options),
                        key: ['kyc_documents', index, documentType.key],
                    };
                    const docNumber = {
                        ...documentNumber,
                        key: ['kyc_documents', index, documentNumber.key],
                    };

                    const docFrontImage = {
                        ...documentFrontImage,
                        key: ['kyc_documents', index, documentFrontImage.key],
                        imageExt: ['kyc_documents', index, 'front_image_details', 'ext'],
                        label: '',
                        imageKey: 'Front',
                    };

                    const docBackImage = {
                        ...documentBackImage,
                        key: ['kyc_documents', index, documentBackImage.key],
                        imageExt: ['kyc_documents', index, 'back_image_details', 'ext'],
                        label: '',
                        imageKey: 'Back',
                    };

                    const docDOB = {
                        ...documentDOB,
                        key: ['kyc_documents', index, documentDOB.key],
                    };

                    const docFileNumber = {
                        ...documentFileNumber,
                        key: ['kyc_documents', index, documentFileNumber.key],
                    };

                    return renderKycIndividual(
                        index, docType, docNumber, docFrontImage, docBackImage, docDOB, docFileNumber,
                    );
                })}
            </>
        );
    };
    return (
        <>
            {loading && <Loader zIndex={10} />}
            {renderBank()}
            <div style={{
                backgroundColor: 'white', height: '50px', fontWeight: 700, display: 'flex', alignItems: 'end',
            }}
            >
                KYC Details
            </div>
            {renderKyc()}
        </>
    );
};

const mapStateToProps = (state: any) => {
    const { masterdataReducer } = state;
    return {
        metadata: masterdataReducer.metadata,
        seller_id: masterdataReducer.seller_id,
    };
};

const StepTwoPage = withStyles(styles, { injectTheme: true })(
    (StepTwo) as React.ComponentType<any>,
);
export default connect(mapStateToProps, null)(StepTwoPage);
