import { NamePath } from 'antd/lib/form/interface';
import { Moment } from 'moment';


export type LabelValue = {
    label: string;
    value: string;
};

export type FormField = {
    imageExt?: NamePath;
    label: string;
    label2?: string;
    type: InputTypes;
    mode?: 'multiple' | 'tags';
    inputType?: 'text' | 'number';
    key: string;
    titleKey?: string;
    valuePropName: string;
    defaultValue?: string | boolean | Moment | Array<string>;
    defaultValue2?: string | boolean | Moment;
    options?: LabelValue[];
    getOptions?: (master: any) => LabelValue[] | null;
    placeholder?: string;
    placeholder2?: string;
    direction?: 'row' | 'column';
    width?: number | string,
    titleWidth?: string | number;
    disabled?: boolean;
    validate?: boolean;
    isRequired?: boolean;
    formitemCss?: Record<string, string | number>;
    labelCss?: Record<string, string | number>;
    inputformCss?: Record<string, string | number>;
    smallMessage1?: string;
    smallMessage2?: string;
    maxFileSize?: number;
    showPreview?: boolean;
    css?: Record<string, string | number>;
    keyPath?: NamePath,
    rules?: any[];
    tooltipMessage?: string;
    imageKey?: string;
};

export enum InputTypes {
    Checkbox = 'Checkbox',
    Input = 'Input',
    Select = 'Select',
    Radio = 'Radio',
    Textarea = 'Textarea',
    Date = 'Date',
    Time = 'Time',
    Number = 'Number',
    Switch = 'Switch',
    Upload = 'Upload',
}
